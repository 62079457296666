:root {
  color: white;
  font-family: "Roboto";
}

.App {
  text-align: center;
  /* display: flex; */
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header-color {
  color: #ffae00;
}

.color-brown {
  color: #ffae00;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 920px) {
  .App {
    flex-direction: column;
  }
}

*::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: #451675;
}

*::-webkit-scrollbar-thumb {
  background-color: #27004d;
  border-radius: 10px;
}

.img-logo-header {
  width: 30px;
}

.bg-linear-gradient {
  /* background-image: linear-gradient(180deg, #1c1e21, #221061); */
  background-image: linear-gradient(180deg, #1b1c1e, #120837);
}

.presale-card {
  background-image: url("../src/assets/img/bros.svg");
  background-size: cover;
}

.textShine {
  -webkit-text-fill-color: #0000;
  text-fill-color: #0000;
  animation: textShine 4s ease-in-out infinite alternate;
  background: linear-gradient(
    90deg,
    #f6ee09 20%,
    #d2970c 30%,
    #fefb97 70%,
    #f8ba29 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 200% auto;
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}

.bgShine {
  background: linear-gradient(
    90deg,
    #f6ee09 20%,
    #d2970c 30%,
    #fff 70%,
    #f8ba29 80%
  );
  animation: textShine 3s ease-in-out infinite alternate;
  background-size: 200% auto;
}

/* .presale-card:before {
  content: "";
  position: absolute;
  inset: 0;
  background: repeating-conic-gradient(from var(--a), #67c319, #ff5447);
  border-radius: 20px;
  animation: rotating 6s linear infinite;
}

.presale-card:after {
  content: "";
  position: absolute;
  inset: 0;
  background: repeating-conic-gradient(from var(--a), #67c319, #ff5447);
  border-radius: 20px;
  animation: rotating 6s linear infinite;
  filter: blur(40px);
  opacity: 0.8;
} */

@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

@keyframes rotating {
  0% {
    --a: 0deg;
  }

  100% {
    --a: 360deg;
  }
}

.el-progress-bar__outer {
  height: 24px;
  border-radius: 100px;
  background-color: var(--el-border-color-lighter);
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.el-progress-bar__inner--striped.el-progress-bar__inner--striped-flow {
  animation: striped-flow 3s linear infinite;
  width: 57.98%;
  animation-duration: 10s;
}

.el-progress-bar__inner--striped {
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1.25em 1.25em;
}

.el-progress-bar__inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--el-color-primary);
  text-align: right;
  border-radius: 100px;
  line-height: 1;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.el-progress-bar__inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--el-color-primary);
  text-align: right;
  border-radius: 100px;
  line-height: 1;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.el-progress-bar__innerText {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 12px;
  margin: 0 5px;
}

.el-progress-bar__inner:after {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}

.pad-left {
  padding-left: 1.5rem !important;
}

.bg-herobtngrad {
  background: #67c319;
  border-color: #67c319;
}

.bg-herobtngrad:hover {
  background: transparent;
  color: #67c319;
}

body section > .max-w-4xl:before {
  content: "";
  position: absolute;
  inset: 0;
  background: repeating-conic-gradient(from var(--a), #67c319, #ff5447);
  border-radius: 20px;
  animation: rotating 6s linear infinite;
}

body section > .max-w-4xl:after {
  content: "";
  position: absolute;
  inset: 0;
  background: repeating-conic-gradient(from var(--a), #67c319, #ff5447);
  border-radius: 20px;
  animation: rotating 6s linear infinite;
  filter: blur(40px);
  opacity: 0.8;
}

.inner-about {
  padding: 10px;
  /* margin: 100px auto; */
  position: relative;
}

.inner-about span {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  background: #222;
  border-radius: 16px;
  z-index: 1;
}

.inner-about video {
  width: 100%;
  border-radius: 16px;
}

div.reflection,
span.reflection {
  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
  position: absolute;
  left: 0;
  /* width: max-content; */
}

.reflection.color-gold {
  background-image: linear-gradient(
    0deg,
    #ffc319 0%,
    #ffc2193e 20%,
    transparent 60%
  );
}

.reflection.color-brown {
  background-image: linear-gradient(
    0deg,
    #ffae00,
    #ffae003e 20%,
    transparent 60%
  );
  background-clip: text;
  -webkit-text-fill-color: #0000;
}

.tokenomic-legend > div {
  height: 50px;
  align-content: center;
}

.lb-feature-subject {
  height: 56px;
  align-content: center;
}

.slider-track {
  display: flex;
  gap: 20px;
  animation: translate-x 40s linear infinite;
}

.MuiAccordion-root .MuiAccordionSummary-content p {
  font-size: 20px;
}

@keyframes translate-x {
  to {
    transform: translateX(-4000px);
  }
}

.slider {
  overflow: hidden;
}

.partners img {
  width: 100px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .inner-about {
    padding: 5px;
  }
}
