.pre-button-area {
  position: absolute;
  right: 20px;
}

.pre-button:hover {
  cursor: pointer;
}

.pre-button {
  display: flex;
  height: 45px;
  width: 186px;
  box-shadow: inset 0 -2px 10px #fff6, inset 0 3px 0 #ffffff4d;
  background: linear-gradient(0deg, #8d0af4, #ef127c 123.44%);
  border-radius: 10px;
  font-family: Inter, serif;
  color: white;
  /* height: 100%; */
  padding: 1px;
  /* width: 100%; */
}

.connect-wallet-btn {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.presale-btn button {
  margin-bottom: 20px;
}

.presale-pay-input {
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
}

.presale-pay-input-balance {
  align-items: center;
  color: gray;
  font-size: 12px;
}

.presale-pay-input-balance .token-icon {
  color: gray;
}

.presale-pay-input-balance .balance-img {
  width: 14px;
  height: 14px;
}

.presale-pay-input-title {
  width: 100%;
  line-height: normal;
  justify-content: space-between;
}

.presale-pay-input-part {
  width: 100%;
  padding-left: 12px;
  border: 1px solid #ffc000;
  border-radius: 32px;
  box-sizing: border-box;
  background: #08131799;
  position: relative;
  height: 41px;
}

.presale-pay-input-part input {
  width: 100%;
  margin: 0 4px;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.presale-pay-input-part input:focus {
  border: none;
  outline: none;
}

.presale-pay-input-part .dropdown-token-relative {
  background: #08363f;
  min-width: 82px;
  /* height: auto; */
  position: relative;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  padding: 12px 8px;
  box-sizing: border-box;
  height: 95%;
  cursor: pointer;
}

.dropdown-token-first {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  z-index: 11;
}

.dropdown-token-list {
  background-color: #0e4352;
  border: none;
  border-radius: 10px;
  left: -1px;
  position: absolute;
  top: 105%;
  width: 101%;
  z-index: 5;

  display: block;
  max-height: 195px;
  margin-right: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid gray;
}

.dropdown-token {
  background: #0e5162;
  border: none;
  height: 100%;
  width: 100%;
  color: white;
}

.dropdown-token-item {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  gap: 4px;
  justify-content: flex-start;
  line-height: normal;
  padding: 12px;
  transition: all 0.3s ease-in-out;
}

.dropdown-token-item:hover {
  background: #0e5162;
}

.dropdown-token:focus {
  outline: none;
}

.dropdown-token-relative svg {
  width: 16px;
  height: 16px;
}

.presale-pay-input-tag {
  border: none;
  border-radius: 10px;
  width: 90%;
}

.startin {
  margin-bottom: 20px;
}

.startin-count {
  display: flex;
  justify-content: center;
  gap: 0.5em;
}

.count-item {
  flex-direction: column;
}

.count-item-segment {
  width: 85px;
  height: 85px;
  align-items: center;
  background-color: #231a51;
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 #0003;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 48px;
}

.flip-card {
  border-radius: 0.1em;
  display: inline-flex;
  flex-direction: column;
  /* font-family: Inter,serif; */
  font-style: normal;
  font-weight: 700;
  position: relative;
}

.flip-card-top {
  /* background-color: #0e5162; */
  /* border-bottom: 1px solid #0000001a; */
  border-top-left-radius: 0.1em;
  border-top-right-radius: 0.1em;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 0.5em;
  line-height: 1;
  overflow: hidden;
  /* padding: 0.25em; */
  /* width: 50px; */
}

.flip-card-bottom {
  align-items: flex-end;
  /* background-color: #231a51; */
  border-bottom-left-radius: 0.1em;
  border-bottom-right-radius: 0.1em;
  color: #c2c0ca;
  display: flex;
  justify-content: center;
  padding-top: 2px;

  height: 0.45em;
  line-height: 1;
  overflow: hidden;
  /* padding: 0.25em; */
}

.price-box {
  margin-bottom: 20px;
}

.price-box svg {
  width: 16px;
  height: 16px;
}

.price-box span {
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.price-box-img {
  width: 16px;
  height: 16px;
}

.coin-img {
  width: 215px;
  height: auto;
}

.access-box {
  margin-bottom: 20px;
}

.buy-token-box {
  flex-direction: column;
}

.token-icon {
  width: 20px;
  height: 20px;
}

.buy-token-title {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
}

.buy-token-body {
  width: 70%;
  height: 51px;
  background-color: #231a51;
  border-radius: 10px;
  box-shadow: inset 0 -2px 5px #fff6, inset 0 3px 0 #ffffff4d;
}

.buy-token-body:hover {
  cursor: pointer;
}

.ic-logo {
  width: 20px;
}

// how to buy
$primary: #3f51b5;
$dark-primary: #303f9f;
$light-primary: #c5cae9;
$text: #ffffff;
$primary-text: #212121;
$secondary-text: #757575;
$accent: #ff4081;

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 400;
  background-color: #eee;
}

h1 {
  font-size: 200%;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 400;
}

header {
  background: $primary;
  color: $text;
  padding: 150px 0;

  p {
    font-family: "Allura";
    color: rgba(255, 255, 255, 0.2);
    margin-bottom: 0;
    font-size: 60px;
    margin-top: -30px;
  }
}

.timeline {
  position: relative;

  &::before {
    content: "";
    background: $light-primary;
    width: 1px;
    height: 94%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;

  &:nth-child(even) {
    .timeline-content {
      float: right;
      padding: 30px 30px 10px 30px;

      .date {
        right: auto;
        left: 0;
      }

      &::after {
        content: "";
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 15px;
        left: -18px;
        border-width: 8px 16px 8px 0;
        border-color: transparent #ff4081d1 transparent transparent;
      }
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.timeline-content {
  position: relative;
  width: 45%;
  padding: 30px 30px 10px 30px;
  border-radius: 4px;
  background: #ffffff0a;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);

  &::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 15px;
    right: -18px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent #ff4081d1;
  }
}

.timeline-img {
  width: 10px;
  height: 10px;
  background: $primary;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 18px;
  margin-left: -5px;
}

.timeline-card {
  padding: 0 !important;

  p {
    padding: 0 20px;
  }

  a {
    margin-left: 20px;
  }
}

.timeline-item {
  .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
      url("https://picsum.photos/1000/800/?random") center center no-repeat;
    background-size: cover;
  }
}

.timeline-img-header {
  height: 200px;
  position: relative;
  margin-bottom: 20px;

  h2 {
    color: $text;
    position: absolute;
    bottom: 5px;
    left: 20px;
  }
}

blockquote {
  margin-top: 30px;
  color: $secondary-text;
  border-left-color: $primary;
  padding: 0 20px;
}

.date {
  background: $accent;
  display: inline-block;
  color: $text;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .timeline {
    margin-left: -48px;

    &::before {
      left: 50px;
    }

    .timeline-img {
      left: 50px;
    }

    .timeline-content {
      max-width: 100%;
      width: auto;
      margin-left: 70px;
    }

    .timeline-item {
      &:nth-child(even) {
        .timeline-content {
          float: none;
          &::after {
            top: 16px;
          }
        }
      }

      &:nth-child(odd) {
        .timeline-content {
          .date {
            left: 0;
            right: inherit;
          }
          &::after {
            content: "";
            position: absolute;
            border-style: solid;
            width: 0;
            height: 0;
            top: 15px;
            left: -18px;
            border-width: 8px 16px 8px 0;
            border-color: transparent #ff4081d1 transparent transparent;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1150px) {
//     .description-part-header {
//         margin-bottom: 10px;
//         justify-content: center;
//         align-items: center;
//     }

//     .description-part-header-title span {
//         font-size: 76px;
//         line-height: 76px;
//         margin-top: 10px;
//         justify-content: center;
//         align-items: center;
//     }

//     .coin-img {
//         width: 185px;
//         height: auto;
//         margin-bottom: 10px;
//     }
// }
